import { useEffect } from 'react'

function Page() {
  useEffect(() => {
    window.location.href = '/circula/produtos'
  }, [])

  return null
}

export const getServerData = async () => {
  return {
    status: 301,
    props: null,
    headers: {
      'cache-control': `s-maxage=31536000, stale-while-revalidate`,
      location: `/circula/produtos`,
    },
  }
}

export default Page
